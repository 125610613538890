<template>
  <Panel aria-labelled-by="panel-title"
         :close-modal-text="getLabels.closeModal"
         event-type="TransferKitAssistant:showModal"
         @open="modalOpen"
         @close="modalClose">
    <div ref="transferKitContent">
      <div class="absolute top-0 left-0 w-full bg-white h-[10px]"></div>
      <div class="absolute top-0 left-0 bg-blue h-[10px] transition-all duration-300 ease-out" :class="getCurrentStep === 3 ? 'w-full' : `w-${getCurrentStep}/3`"></div>
      <div class="relative">
        <transition-group :name="getCurrentStep < oldStep ? 'main-section' : 'secondary-section'">
          <step-serial-number class="step-1" v-if="getCurrentStep === 1" key="1" />
          <step-vehicle-from class="step-2" v-if="getCurrentStep === 2" key="2" />
          <step-vehicle-to class="step-3" v-if="getCurrentStep === 3 || getCurrentStep === 4" key="3" />
        </transition-group>
      </div>
    </div>
  </Panel>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

import StepSerialNumber from './StepSerialNumber.vue';
import StepVehicleFrom from './StepVehicleFrom.vue';
import StepVehicleTo from './StepVehicleTo.vue';
import Panel from '../Panel.vue';

export default {
  components: {
    Panel,
    StepSerialNumber,
    StepVehicleFrom,
    StepVehicleTo
  },
  props: {
    labels: null,
    resultsUrl: null,
    globals: null
  },
  data: () => {
    return {
      oldStep: 1
    }
  },
  watch: {
    getCurrentStep: function(step, oldStep) {
      this.oldStep = oldStep;
      if (this.$refs.transferKitContent) {
        this.$refs.transferKitContent.closest('.panel-content-container').scrollTop = 0;
      }
      if (step === 4) {
        const url = new URL(this.resultsUrl);
        url.searchParams.set('serialNumber', this.getSerialNumber);
        url.searchParams.set('fromBrand', this.getFromBrandValue);
        url.searchParams.set('fromYear', this.getFromYearValue);
        url.searchParams.set('fromModel', this.getFromModelValue);
        url.searchParams.set('toBrand', this.getToBrandValue);
        url.searchParams.set('toYear', this.getToYearValue);
        url.searchParams.set('toModel', this.getToModelValue);
        window.location.href = url.toString();
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLabels',
      'getGlobals',
      'getLang',
      'getConversionKitData',
      'getSerialNumberData',
      'getCurrentStep',
      'getModalActive',
      'getSerialNumber',
      'getFromBrandValue',
      'getFromYearValue',
      'getFromModelValue',
      'getToBrandValue',
      'getToYearValue',
      'getToModelValue',
    ])
  },
  methods: {
    ...mapMutations([
      'setLabels',
      'setGlobals',
      'setLang',
      'setConversionKitData',
      'setSerialNumberData',
      'setModalActive',
      'resetState'
    ]),
    ...mapActions(['updateSerialNumberFromApi']),
    modalClose () {
    },
    modalOpen () {
      this.resetState();
      this.updateSerialNumberFromApi();
    }
  },
  created () {
    this.setLabels(this.labels);
    this.setGlobals(this.globals);
    this.setLang(document.querySelector('html').getAttribute('lang'));
  },
  mounted () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const transferKitParam = urlParams.get('transferKit');
    if (transferKitParam) {
      const event = new CustomEvent("TransferKitAssistant:showModal", { detail: { buttonElement: null } });
      document.dispatchEvent(event);
    }
  }
};
</script>

<style scoped>
.main-section-enter-active,
.main-section-leave-active,
.secondary-section-enter-active,
.secondary-section-leave-active {
  transition: all 0.5s ease;
}
.main-section-enter-active,
.secondary-section-enter-active {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.main-section-enter-from,
.main-section-leave-to,
.secondary-section-enter-from,
.secondary-section-leave-to {
  opacity: 0;
}
.main-section-enter-from {
  transform: translateX(-100%);
}
.main-section-leave-to {
  transform: translateX(100%);
}
.secondary-section-enter-from {
  transform: translateX(100%);
}
.secondary-section-leave-to {
  transform: translateX(-100%);
}
</style>
