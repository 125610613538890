import intlTelInput from 'intl-tel-input';
import { fr } from "intl-tel-input/i18n";

export default function () {
  const isFrench = document.documentElement.getAttribute("lang").includes("fr");
  const isUs = document.documentElement.getAttribute("lang").includes("US");
  document.querySelectorAll("input[type='tel']").forEach(el => {
    intlTelInput(el, {
      strictMode: true,
      i18n: isFrench ? fr : {},
      initialCountry: isUs ? "us" : "ca",
      loadUtilsOnInit: () => import("intl-tel-input/utils")
    });
  });
}
