<template>
  <div ref="storeLocatorContainer" class="flex flex-col-reverse lg:flex-row lg:h-screen text-black">
    <GoogleMap
      ref="mapRef"
      class="w-full lg:h-full"
      :class="active == 'map' || windowWidth >= 1024 ? 'opacity-100 h-screen' : 'opacity-0 h-0'"
      :api-key="googleMapsApiKey"
      :map-id="googleMapsMapId"
      :zoom="4"
      :center="{lat: 50, lng: -98}"
    >
      <CustomMarker
        v-for="contact in mapContacts"
        :options="{ position: contact.position }"
        @click="onMarkerClick(contact)"
        :class="contact.ID === currentContact?.ID ? 'text-blue z-10' : 'text-black'"
      >
        <InfoWindow
          v-if="contact.ID === currentContact?.ID"
          :options="{ position: contact.position, headerContent: contact.CompanyName }"
        >
          <div class="text-sm text-black">
            <div v-if="currentLatLng && contact.position.lat !== 0 && contact.position.lng !== 0">
              {{ getDistance(contact) }} km
            </div>
            <div v-if="contact.Phone">{{ contact.Phone }}</div>
            <div v-if="contact.Emails.length > 0">
              <a v-for="email in contact.Emails" :href="email" target="_blank">{{ email }}</a>
            </div>
            <div v-if="contact.WebSites.length > 0">
              <a v-for="website in contact.WebSites" :href="website" target="_blank">
                {{ website.replace(/https?\:\/\//i, "") }}
              </a>
            </div>
            <div class="flex mt-4 gap-2">
              <span v-for="industry in contact.Industries.filter(s => s !== 'Service')"
                    class="text-xs px-2 py-1 bg-gray-light text-gray">
                {{ industry }}
              </span>
              <span v-for="service in contact.Services.filter(s => s !== 'Service')"
                    class="text-xs px-2 py-1 bg-gray-light text-gray">
                {{ service }}
              </span>
            </div>

          </div>
        </InfoWindow>
        <Marker />
      </CustomMarker>
    </GoogleMap>

    <div class="w-full lg:h-full lg:max-w-[490px] text-white">
      <div class="py-6 lg:py-8 container">
        <h2 class="h4">{{ labels.storeLocator }}</h2>
        <div class="mt-6 lg:mt-8 flex">
          <form class="flex-1 relative" @submit.prevent="searchAddress" @reset.prevent="clearAddress">
            <input class="form-input w-full pr-12" v-model="address" :placeholder="labels.inputPlaceholder">
            <button v-if="currentLatLng"
                    type="reset"
                    class="text-blue h-full px-4 absolute right-0 top-0">
              <Close />
            </button>
            <button v-if="!currentLatLng"
                    type="submit"
                    class="text-blue h-full px-4 absolute right-0 top-0">
              <Search />
            </button>
          </form>
          <div class="w-14 flex items-center justify-center">
            <div v-show="isLoadingLocation" class="loader"></div>
            <button v-show="!isLoadingLocation" class="h-full" @click="useCurrentLocation">
              <CurrentLocation />
            </button>
          </div>
        </div>
        <div v-if="invalidAddress">
          <div class="mt-6 lg:mt-8 text-sm bg-red rounded py-1.5 px-2 inline-block">
            {{ labels.invalidAddress }}
          </div>
        </div>
        <div v-show="currentLatLng">
          <div class="mt-6 lg:mt-8 text-sm">
            {{ labels.selectTopic }}
          </div>
          <div class="mt-4 flex items-start flex-wrap gap-4 text-sm">
            <button class="px-3 py-2 border border-white"
                    :class="showAtvUtv ? 'bg-blue !border-blue' :  ''"
                    @click="showAtvUtv = !showAtvUtv">
              {{ labels.atvUtv }}
            </button>
            <button class="px-3 py-2 border border-white"
                    :class="showSnowmobile ? 'bg-blue !border-blue' :  ''"
                    @click="showSnowmobile = !showSnowmobile">
              {{ labels.snowmobile }}
            </button>
            <!--<button class="px-3 py-2 border border-white"
                    :class="showDealers ? 'bg-blue !border-blue' :  ''"
                    @click="showDealers = !showDealers">
              {{ labels.dealers }}
            </button>-->
            <button class="px-3 py-2 border border-white"
                    :class="showDistributors ? 'bg-blue !border-blue' :  ''"
                    @click="showDistributors = !showDistributors">
              {{ labels.distributors }}
            </button>
          </div>
        </div>
      </div>
      <div v-show="currentLatLng" class="grid grid-cols-2 text-black bg-white lg:hidden">
        <button
          class="p-3 border-b-[3px] "
          :class="active === 'list' ? 'border-blue text-blue' : 'border-white'"
          @click="active = 'list'"
        >{{ labels.list }}</button>
        <button
          class="p-3 border-b-[3px] "
          :class="active === 'map' ? 'border-blue text-blue' : 'border-white'"
          @click="active = 'map'"
        >{{ labels.map }}</button>
      </div>
      <div
        ref="contactsContainer"
        :class="(active === 'list' || windowWidth >= 1024 ? 'opacity-100 h-screen' : 'opacity-0 h-0') + ' ' + (currentLatLng ? 'bg-white' : 'bg-black')"
        class="text-black lg:flex-1 overflow-y-auto"
      >
        <div :data-contact-id="contact.ID"
             class="py-6 border-b border-gray-medium"
             :class="{'bg-cloud': contact.ID === currentContact?.ID}"
             v-for="contact in contacts"
             @click="onContactClick(contact)">
          <div class="container">

            <div class="flex gap-4 justify-between mb-1">
              <h3 class="text-lg">{{ contact.CompanyName }}</h3>
              <div class="text-lg" v-if="currentLatLng && contact.position.lat !== 0 && contact.position.lng !== 0">
                {{ getDistance(contact) }} km
              </div>
            </div>
            <div v-if="contact.Phone" class="text-sm text-gray">{{ contact.Phone }}</div>
            <div v-if="contact.Emails.length > 0" class="text-sm text-gray">
              <a v-for="email in contact.Emails" :href="email" target="_blank">{{ email }}</a>
            </div>
            <div v-if="contact.WebSites.length > 0" class="text-sm text-gray">
              <a v-for="website in contact.WebSites" :href="website" target="_blank">
                {{ website.replace(/https?\:\/\//i, "") }}
              </a>
            </div>
            <div class="flex mt-4 gap-2">
              <span v-for="industry in contact.Industries.filter(s => s !== 'Service')"
                    class="text-xs px-2 py-1 bg-gray-light text-gray">
                {{ industry }}
              </span>
              <span v-for="service in contact.Services.filter(s => s !== 'Service')"
                    class="text-xs px-2 py-1 bg-gray-light text-gray">
                {{ service }}
              </span>
            </div>
            <div class="mt-4" v-if="contact.position.lat !== 0 && contact.position.lng !== 0">
              <a :href="'https://maps.google.com/?q=' + contact.position.lat + ',' + contact.position.lng"
                 target="_blank"
                 class="text-sm underline underline-offset-2 hover:no-underline text-blue">
                {{ labels.openInGoogleMaps }}
                <external-link class="inline" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { GoogleMap, CustomMarker, InfoWindow } from 'vue3-google-map'
import axios from 'axios';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import Marker from '../../icons/marker.svg';
import ExternalLink from '../../icons/external-link.svg';
import CurrentLocation from '../../icons/current-location.svg';
import Close from '../../icons/close.svg';
import Search from '../../icons/search.svg';

const props = defineProps(['labels', 'language', 'apiUrl', 'googleMapsApiKey', 'googleMapsMapId', 'googleGeocodingApiKey']);

const storeLocatorContainer = ref(null);
const contactsContainer = ref(null);
const mapRef = ref(null);
const apiContacts = ref([]);
const currentContact = ref(null);
const address = ref("");
const invalidAddress = ref(false);
const currentLatLng = ref(null);
const geometryApi = ref(null);

const showAtvUtv = ref(false);
const showSnowmobile = ref(false);
const showDealers = ref(false);
const showDistributors = ref(false);
const infoWindow = ref(false);
const active = ref("list");
const isLoadingLocation = ref(false);
let windowWidth = ref(window.innerWidth);

const contacts = computed(() => {
  return apiContacts.value.map(c => {
    c.position = { lat: c.Latitude, lng: c.Longitude };
    return c;
  });
});

const mapContacts = computed(() => {
  return contacts.value.filter(c => c.Latitude !== 0 && c.Longitude !== 0);
});

const setMapCenter = () => {
  if (!mapRef.value?.ready || mapContacts.value.length === 0) return;
  if (currentLatLng.value) {
    mapRef.value.map.setCenter(currentLatLng.value);
    mapRef.value.map.setZoom(12);
  } else {
    const bounds = new window.google.maps.LatLngBounds();
    mapContacts.value.forEach((c) => {
      bounds.extend(c.position);
    });
    mapRef.value.map.fitBounds(bounds);
  }
}

const searchContacts = async () => {
  if (!currentLatLng.value) {
    apiContacts.value = [];
    return;
  }

  const url = new URL(props.apiUrl);

  url.searchParams.set('lang', props.language);

  url.searchParams.set('latitude', currentLatLng.value.lat);
  url.searchParams.set('longitude', currentLatLng.value.lng);

  if (showAtvUtv.value !== showSnowmobile.value) {
    if (showAtvUtv.value) {
      url.searchParams.set('industry', 'PS_AU');
    } else if (showSnowmobile.value) {
      url.searchParams.set('industry', 'PS_SM');
    }
  }

  if (showDealers.value !== showDistributors.value) {
    if (showDealers.value) {
      url.searchParams.set('service', '1');
    } else if (showDistributors.value) {
      url.searchParams.set('service', '6');
    }
  }

  const res = await axios.get(url.toString());

  apiContacts.value = res.data.Contacts;
};

function scrollParentToChild(parent, child) {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect();
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };

  // Where is the child
  const childRect = child.getBoundingClientRect();
  // Is the child viewable?
  const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    const scrollTop = childRect.top - parentRect.top;
    parent.scrollTop += scrollTop;
  }
}

const onMarkerClick = (contact) => {
  currentContact.value = contact;
  scrollParentToChild(contactsContainer.value, contactsContainer.value.querySelector('[data-contact-id="' + currentContact.value.ID + '"]'));
  storeLocatorContainer.value.scrollIntoView();
}

const onContactClick = (contact) => {
  currentContact.value = contact;
  if (!mapRef.value?.ready || contact.Latitude === 0 || contact.Longitude === 0) return;
  mapRef.value.map.setZoom(14);
  mapRef.value.map.setCenter({ lat: contact.Latitude, lng: contact.Longitude });
}

const isCanadianPostalCode = (address) => {
  return address.match(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
}

const formatCanadianPostalCode = (postalCode) => {
  return postalCode.trim().toUpperCase().replace(/\W/g,'').replace(/(...)/,'$1 ');
}

const getLatLngFromAddress = async () => {
  if (address.value.trim().length < 3) {
    invalidAddress.value = false;
    currentLatLng.value = null;
    return;
  }

  const url = new URL("https://maps.googleapis.com/maps/api/geocode/json");

  let searchAddress = address.value;
  if (isCanadianPostalCode(address.value)) {
    searchAddress = formatCanadianPostalCode(address.value) + ", Canada";
  }

  url.searchParams.set('address', searchAddress);
  url.searchParams.set('key', props.googleGeocodingApiKey);

  const res = await axios.get(url.toString());

  if (res.data.results.length > 0) {
    invalidAddress.value = false;
    currentLatLng.value = res.data.results[0].geometry.location;
  } else {
    invalidAddress.value = true;
    currentLatLng.value = null;
  }
}

const useCurrentLocation = () => {
  if(navigator.geolocation) {
    isLoadingLocation.value = true;
    navigator.geolocation.getCurrentPosition( (position) => {
      isLoadingLocation.value = false;
      currentLatLng.value = {lat: position.coords.latitude, lng: position.coords.longitude};
    }, () => {

    });
  }
}

const searchAddress = () => {
  getLatLngFromAddress();
}

const clearAddress = () => {
  currentLatLng.value = null;
  address.value = '';
}

const getDistance = (contact) => {
  if (contact.Latitude === 0 || contact.Longitude === 0) return null;
  const distance = geometryApi.value.spherical.computeDistanceBetween(contact.position, currentLatLng.value);
  return Math.round(distance / 1000 * 10) / 10;
}

const onScreenResize = () => {
  windowWidth.value = window.innerWidth;
}

onMounted(() => window.addEventListener('resize', onScreenResize));

watch(() => mapRef.value?.ready, async () => {
  geometryApi.value = await google.maps.importLibrary("geometry");
  setMapCenter();
});
watch(() => mapContacts.value, setMapCenter);
watch(() => active.value, () => {
  if (active.value === 'map') {
    nextTick(() => {
      setMapCenter();
    });
  }
});
watch(() => currentLatLng.value, searchContacts);
watch(() => showAtvUtv.value, searchContacts);
watch(() => showSnowmobile.value, searchContacts);
watch(() => showDealers.value, searchContacts);
watch(() => showDistributors.value, searchContacts);

searchContacts();
</script>

<style>
.gm-style-iw {
  font-family: 'LLAkkuratWeb', 'myriad-pro', 'Arial', 'sans-serif';
}

.gm-style-iw-ch span {
  font-size: 18px;
}
</style>
