<template>
  <div class="lg:flex">
    <select-vehicle-panel :labels="labels" :craft-products="craftProducts" />

    <div class="lg:w-2/5 xl:w-1/4 lg:pr-12 mb-10 lg:mb-0">
      <div>
        <div class="bg-gray-light px-4 py-6">
          <h2 class="text-xl mb-2 flex items-center gap-2" :class="selectedVehicle ? 'text-blue' : ''">
            <blue-check v-if="selectedVehicle" />
            {{ selectedVehicle ? labels.selectedVehicleTitle : labels.selectVehicleTitle }}
          </h2>
          <div class="mb-6 text-sm">
            {{ selectedVehicle ? selectedVehicle : labels.selectVehicleText }}
          </div>
          <primary-button
            css-class="btn-primary-blue btn-full"
            @click="openSelectVehicle($event)"
            :label="selectedVehicle ? labels.selectedVehicleButton : labels.selectVehicleButton"
          />
        </div>

        <div :class="showFilters ? '' : 'hidden lg:block'">
          <div class="mt-10" v-if="categories.filter(c => !c.vehicle).length > 0">
            <Select
              v-for="cat in categories.filter(c => !c.vehicle)"
              class="mb-6"
              :disabled="cat.list.length === 0"
              show-clear
              :placeholder="labels['select' + capitalizeFirstLetter(cat.name)]"
              :model-value="cat.value"
              :options="cat.list"
              option-label="TagName"
              option-value="ID"
              @update:modelValue="onModelChange(cat, $event)"
            ></Select>
          </div>

          <InputText
            v-if="showFilterByName"
            class="mb-6"
            show-clear
            fluid
            :placeholder="labels.filterByName"
            v-model="name"
          />

          <primary-button
            v-if="showClearAllFields"
            css-class="btn-primary-blue btn-full"
            @click="clearAllFields"
            :label="labels.clearAllFields"
          />
        </div>
      </div>
    </div>

    <div class="lg:w-3/5 xl:w-3/4">
      <div class="lg:hidden mb-10" :class="showFilters ? '' : 'hidden'">
        <div class="form-select-underline">
          <label for="product-sortby">{{ labels.sortBy }}:</label>
          <select id="product-sortby" v-model="sort">
            <option value="bestseller">{{ labels.bestSellers }}</option>
            <option value="title-ascending">{{ labels.alphabeticallyAsc }}</option>
            <option value="title-descending">{{ labels.alphabeticallyDesc }}</option>
            <option value="price-ascending">{{ labels.priceAsc }}</option>
            <option value="price-descending">{{ labels.priceDesc }}</option>
          </select>
        </div>
      </div>
      <div class="mb-6 sm:mb-10 flex justify-between items-center gap-4">
        <div>{{ filteredProducts.length }} {{ labels.products }}</div>
        <div class="hidden lg:block">
          <div class="form-select-underline">
            <label for="product-sortby">{{ labels.sortBy }}:</label>
            <select id="product-sortby" v-model="sort">
              <option value="bestseller">{{ labels.bestSellers }}</option>
              <option value="title-ascending">{{ labels.alphabeticallyAsc }}</option>
              <option value="title-descending">{{ labels.alphabeticallyDesc }}</option>
              <option value="price-ascending">{{ labels.priceAsc }}</option>
              <option value="price-descending">{{ labels.priceDesc }}</option>
            </select>
          </div>
        </div>
        <primary-button css-class="btn-primary-dark lg:hidden"
                        :label="labels.filterAndSort"
                        @click="showFilters = !showFilters" />
      </div>
      <div class="grid sm:grid-cols-2 gap-6 lg:gap-4">
        <div class="relative border border-gray-light" v-for="product in filteredProducts" :key="product.id">
          <div v-if="product.tags" class="card-tags-wrapper absolute left-0 top-0 p-3 xl:p-4 flex-wrap z-10">
            <span class="card-tag" v-for="tag in product.tags">{{ tag }}</span>
          </div>
          <div class="group lg:relative lg:overflow-hidden">
            <a class="block" :href="product.url + '?' + searchParams.toString()" tabindex="-1" aria-hidden="true">
              <img loading="lazy" :src="product.image" :alt="product.imageAlt" />
              <div class="btn-primary-blue flex justify-center items-center absolute bottom-4 lg:-bottom-12 left-3 right-3
                          lg:left-4 lg:right-4 transition-all duration-300 ease-out lg:group-hover:bottom-4">
                {{ labels.viewProduct }}
              </div>
            </a>
          </div>
          <div class="px-3 sm:px-4 py-4 sm:py-8 pb-20 sm:pb-20 lg:pb-8">
            <h3 class="text-[33px] heading">
              <a :href="product.url + '?' + searchParams.toString()">{{ product.title }}</a>
            </h3>
            <div class="mt-1 sm:mt-2 text-sm sm:text-base">{{ product.headline }}</div>
            <div class="mt-4 flex justify-between items-center">
              <div class="text-lg [&_sup]:text-[11px]" v-html="product.price"></div>
              <div class="text-sm" v-text="product.feature"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Select from 'primevue/select';
import InputText from 'primevue/inputtext';
import PrimaryButton from './PrimaryButton.vue';
import SelectVehiclePanel from './SelectVehiclePanel.vue';
import BlueCheck from '../../icons/blue-check.svg';
import { capitalizeFirstLetter } from '../utils/string.js';

const props = defineProps([
  'labels',
  'language',
  'craftProducts',
  'selectorCategories',
  'apiUrl',
  'showFilterByName',
  'showClearAllFields'
]);

const store = useStore();

store.commit('setApiUrl', props.apiUrl);
store.commit('setLanguage', props.language);

store.dispatch('initialiseCategories', props.selectorCategories);
store.dispatch('setCategoriesFromUrl');
store.dispatch('searchProducts');

const categories = computed(() => store.getters.categories);
const searchParams = computed(() => store.getters.searchParams);

const showFilters = ref(false);
const name = ref('');
const sort = ref('bestseller');

const onModelChange = (category, value) => {
  store.dispatch('updateCategory', { category, value });
};

const clearAllFields = () => {
  name.value = '';
  store.dispatch('clearAllFields');
};

const selectedVehicle = computed(() => {
  const selectedVehicles = [];
  const vehicleCategories = categories.value.filter((c) => c.vehicle);
  vehicleCategories.forEach((c) => {
    if (c.value) {
      const categoryName = c.list.find((cat) => cat.ID == c.value)?.TagName;
      if (categoryName) {
        selectedVehicles.push(categoryName);
      }
    }
  });
  return selectedVehicles.length === 3 ? selectedVehicles.join(' / ') : null;
});

const filteredProducts = computed(() => {
  const productNumbers = store.getters.products.map((p) => p.ProductNumber);
  const products = props.craftProducts.filter((p) =>
    p.productNumber.split(',').some((productNumber) => productNumbers.includes(productNumber))
  );
  return products
    .filter((p) => p.title.toLowerCase().includes(name.value.toLowerCase()))
    .sort((a , b) => {
      if (sort.value === 'title-ascending') {
        return a.title.localeCompare(b.title);
      } else if (sort.value === 'title-descending') {
        return b.title.localeCompare(a.title);
      } else if (sort.value === 'price-ascending') {
        return a.priceAmount - b.priceAmount;
      } else if (sort.value === 'price-descending') {
        return b.priceAmount - a.priceAmount;
      }
      return a.order - b.order;
    });
});

const openSelectVehicle = (event) => {
  const customEvent = new CustomEvent('SelectVehicle:showModal', { detail: { buttonElement: event.currentTarget } });
  document.dispatchEvent(customEvent);
};
</script>
