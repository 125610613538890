<template>
  <div>
    <select-vehicle-panel :labels="labels"
                          :craft-product-number="craftProductNumber"
                          :craft-products="craftProducts"
                          @select-vehicle="onSelectVehicle"
                          @open="onOpen" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import SelectVehiclePanel from './SelectVehiclePanel.vue';

const props = defineProps([
  'labels',
  'language',
  'craftProducts',
  'craftProductNumber',
  'selectorCategories',
  'productsPageUrl',
  'apiUrl'
]);

const store = useStore();

store.commit('setApiUrl', props.apiUrl);
store.commit('setLanguage', props.language);

const onOpen = () => {
  store.dispatch('initialiseCategories', props.selectorCategories);
  store.dispatch('setCategoriesFromUrl');
  store.dispatch('searchProducts');
}

const onSelectVehicle = () => {
  if (props.productsPageUrl) {
    window.location.href = props.productsPageUrl + window.location.search;
  } else {
    window.location.reload();
  }
}

</script>
