import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

export default function () {
  const cardsWrapper = document.querySelector(".cards-wrapper");
  if (!cardsWrapper) return;

  const cards = Array.from(cardsWrapper.querySelectorAll(".card"));
  const steps = cardsWrapper.querySelectorAll(`.step`);
  const tagsWrapper = cardsWrapper.querySelector(".card-tags-wrapper");
  const tagsWrapperHeight = tagsWrapper ? tagsWrapper.offsetHeight : 30;
  const stepsWrapper = cardsWrapper.querySelector('.steps-wrapper');

  cards.forEach((card, i) => {
    card.style.paddingTop = `${i * tagsWrapperHeight}px`;
    const tagsWrapper = card.querySelector('.card-tags-wrapper');
    if (tagsWrapper) {
      tagsWrapper.style.top = `${i * tagsWrapperHeight}px`;
    }
  });

  let lastScrollTop = document.documentElement.scrollTop;
  let isScrolling = false;

  window.addEventListener("scroll", () => {
    const st = document.documentElement.scrollTop;

    let cardIndex = -1;
    let stepIndex = 1;

    cards.forEach((card, index) => {
      card.classList.remove("active");

      if (card.getBoundingClientRect().top <= (window.innerHeight / 2)) {
        stepIndex = index + 1;
      }

      if (st > lastScrollTop) {
        const snapPosition = index === 0 ? (window.innerHeight / 4) : window.innerHeight - (window.innerHeight / 4);
        if (card.getBoundingClientRect().top > 1 && card.getBoundingClientRect().top <= snapPosition) {
          cardIndex = index;
        }
      }
    });

    if (cardIndex > -1 && !isScrolling) {
      isScrolling = true;
      document.documentElement.classList.add("no-scroll");
      gsap.to(window, {
        duration: cardIndex === 0 ? .4 : .8,
        scrollTo: {
          y: cards[cardIndex]
        },
        onComplete: () => {
          isScrolling = false;
          document.documentElement.classList.remove("no-scroll");
        } });
    }

    if (stepsWrapper) {
      steps.forEach(step => {
        step.classList.remove('active');
      });

      steps[stepIndex - 1].classList.add('active');
    }

    lastScrollTop = st <= 0 ? 0 : st;
  });
}
