export default function () {
  document.querySelectorAll('.share-container').forEach(el => {
    const shareButton = el.querySelector('.share-button');
    const shareNotification = el.querySelector('.share-notification');
    shareButton.addEventListener('click', () => {
      shareNotification.classList.add('active');
      navigator.clipboard.writeText(window.location.href);
      setTimeout(() => {
        shareNotification.classList.remove('active');
      }, 3000);
    });
  });
}
