import ShopifyBuy from 'shopify-buy';

export default function() {
  const CHECKOUT_LOCAL_STORAGE = "myCheckoutPowersports";
  const CHECKOUT_COOKIE = "myCheckoutPowersportsId";

  const $floatingCart = document.querySelector("#floating-cart");

  const CADollar = new Intl.NumberFormat(document.documentElement.getAttribute('lang'), {
    style: 'currency',
    currency: 'CAD',
  });

  // eslint-disable-next-line no-undef
  const client = ShopifyBuy.buildClient({
    domain: window.storefrontDomain,
    storefrontAccessToken: window.storefrontAccessToken,
  });

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const closeFloatingCart = () => {
    $floatingCart.classList.add("hidden");
  };

  const openFloatingCart = () => {
    $floatingCart.classList.remove("hidden");
  };


  const updateLocalStorage = (checkout) => {
    setCookie(CHECKOUT_COOKIE, checkout.id, 365);
    localStorage.setItem(CHECKOUT_LOCAL_STORAGE, JSON.stringify(checkout));
  };

  const updateCartItems = (checkout) => {
    const $cartItems = document.querySelectorAll("[data-cart-items]");
    $cartItems.forEach($cartItem => {
      $cartItem.innerText = checkout.lineItems.reduce((n, {quantity}) => n + quantity, 0);
    });
  };

  const updateCartSubtotal = (checkout) => {
    document.querySelectorAll('[data-cart-subtotal]').forEach($el => {
      $el.innerText = CADollar.format(checkout.subtotalPrice.amount);
    });
  };

  const onCheckoutReady = async (checkout) => {
    if (checkout.completedAt) {
      checkout = await client.checkout.create();
    }

    updateLocalStorage(checkout);
    updateCartItems(checkout);

    document.querySelectorAll(".buy-button").forEach(($b) => {
      $b.addEventListener("click", () => {
        $b.classList.add("loading");
        const variantsDataset = $b.dataset.defaultVariantId;
        const titlesDataset = $b.dataset.productTitle;
        let variants;
        try {
          variants = JSON.parse(variantsDataset);
        } catch(e) {
          variants = [variantsDataset];
        }
        if (!Array.isArray(variants)) {
          variants = [variantsDataset];
        }
        let titles;
        try {
          titles = JSON.parse(titlesDataset);
        } catch(e) {
          titles = [titlesDataset];
        }
        const lineItems = [];
        variants.forEach(v => {
          lineItems.push({
            variantId: `gid://shopify/ProductVariant/${v}`,
            quantity: parseInt($b.dataset.quantity || 1),
          });
        });
        client.checkout
          .addLineItems(checkout.id, lineItems)
          .then((c) => {
            updateLocalStorage(c);
            updateCartItems(c);
            $b.classList.remove("loading");
            if (c.userErrors.length === 0) {
              updateCartSubtotal(c);
              document.querySelectorAll('[data-cart-item-added]').forEach($el => {
                $el.innerHTML = titles.map(t => "<p>" + t + "</p>").join(" ");
              });
              document.querySelectorAll('[data-checkout-url]').forEach($el => {
                $el.setAttribute('href', c.webUrl);
              });
              if ($floatingCart) {
                openFloatingCart();
              }
            }
          })
          .catch(() => {
            $b.classList.remove("loading");
          });
      });
    });

    document.querySelectorAll('[data-cart-line-item]').forEach(($p) => {
      $p.querySelector('input').addEventListener("change", () => {
        const lineItems = [];
        document.querySelectorAll('[data-cart-line-item]').forEach($i => {
          lineItems.push({
            id: $i.dataset.cartLineItem,
            quantity: parseInt($i.querySelector('input').value)
          });
        });
        client.checkout
          .updateLineItems(checkout.id, lineItems)
          .then((checkout) => {
            updateLocalStorage(checkout);
            updateCartItems(checkout);
            checkout.lineItems.forEach(l => {
              const $lineItem = document.querySelector('[data-cart-line-item="' + l.id + '"]');
              $lineItem.querySelector('[data-price]').innerText = CADollar.format(l.variant.price.amount * l.quantity);
            });
            updateCartSubtotal(checkout);
          });
      });
      $p.querySelectorAll('[data-delete]').forEach(btnDelete =>
        btnDelete.addEventListener("click", () => {
          client.checkout.removeLineItems(checkout.id, [$p.dataset.cartLineItem]).then((checkout) => {
            updateLocalStorage(checkout);
            updateCartItems(checkout);
            $p.remove();
            updateCartSubtotal(checkout);
          });
        })
      );
    });
  };

  const checkoutLocalStorage = localStorage.getItem(CHECKOUT_LOCAL_STORAGE);

  if (checkoutLocalStorage) {
    const checkout = JSON.parse(checkoutLocalStorage);
    client.checkout.fetch(checkout.id).then(onCheckoutReady);
  } else {
    client.checkout.create().then(onCheckoutReady);
  }

  document.querySelectorAll('[data-close-floating-cart]').forEach($el => {
    $el.addEventListener("click", () => {
      if ($floatingCart) {
        closeFloatingCart();
      }
    });
  });

  document.body.addEventListener('click', (e) => {
    if ($floatingCart) {
      if (e.target !== $floatingCart && !$floatingCart.contains(e.target)) {
        closeFloatingCart();
      }
    }
  });
}
